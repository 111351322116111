<template>
  <div>
    <tabBar :cardIndex="500" :mouseenterIndex="20" style="height: 90px" />
    <div class="ckoseCenter">
      <img class="imgas" src="../assets/img/ok51929.png" alt="" />
      <div class="btmnslsit">
        <div class="tooders" @click="tooders(1)">查看订单</div>
        <div class="tohomens" @click="tooders(2)">返回首页</div>
      </div>
    </div>
    <footers class="foos"></footers>
  </div>
</template>

<script>
import tabBar from "@/components/tabBar.vue";
import footers from "@/components/footer.vue";
export default {
  components: {
    tabBar,
    footers,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
      tooders(index){
          if(index == 1){
               this.$router.push({ path: "/mepages/myoder?active=1" });
          }
           if(index == 2){
               this.$router.push({ path: "/" });
          }
      },
  },
};
</script>

<style lang="scss" scoped>
.ckoseCenter {
  width: 100%;
  background: #ffffff;
  overflow: hidden;
  height: 700px;
  text-align: center;
//   margin-bottom: 80px;
  .imgas {
      width: 300px;
    margin-top: 80px;
  }
  .btmnslsit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 26px;
    margin-top: 90px;
    
    .tooders {
      padding: 14px 56px;
      background: #0075aa;
      border-radius: 10px;
      color: #ffffff;
      margin-right: 40px;
    }
    .tohomens {
      padding: 14px 56px;
      color: #999999;
      background: #ffffff;
      border: 2px solid #b5b5b5;
      border-radius: 10px;
    }
  }
}
</style>